.box {
  background-color: var(--deep-blue);
  color: var(--white);
  min-height: 116px;
}
@media screen and (min-width: 1200px) {
  .box {
    height: 238px;
  }
}
@media screen and (max-width: 992px) {
  .box {
    max-width: 992px;
    width: 90vw;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .box {
    height: 146px;
  }
}

.box-wrapper {
  background-color: var(--light-green);
}

.rounded-border-top-right {
  border-top-right-radius: var(--big-radius);
}

.rounded-border-top-left {
  border-top-left-radius: var(--big-radius);
}

.rounded-border-bottom-right {
  border-bottom-right-radius: var(--big-radius);
}

.rounded-border-bottom-left {
  border-bottom-left-radius: var(--big-radius);
}

.top-outline {
  border-top: var(--outline-width) solid var(--light-green);
}

.right-outline {
  border-right: var(--outline-width) solid var(--light-green);
}

.left-outline {
  border-left: var(--outline-width) solid var(--light-green);
}

.bottom-outline {
  border-bottom: var(--outline-width) solid var(--light-green);
}

.top-outline-margin {
  margin-top: var(--outline-width);
}

.right-outline-margin {
  margin-right: var(--outline-width);
}

.left-outline-margin {
  margin-left: var(--outline-width);
}

.bottom-outline-margin {
  margin-bottom: var(--outline-width);
}

@media screen and (max-width: 992px) {
  .top-outline {
    border-top-width: var(--outline-thin-width);
  }

  .right-outline {
    border-right-width: var(--outline-thin-width);
  }

  .left-outline {
    border-left-width: var(--outline-thin-width);
  }

  .bottom-outline {
    border-bottom-width: var(--outline-thin-width);
  }

  .top-outline-margin {
    margin-top: var(--outline-thin-width);
  }

  .right-outline-margin {
    margin-right: var(--outline-thin-width);
  }

  .left-outline-margin {
    margin-left: var(--outline-thin-width);
  }

  .bottom-outline-margin {
    margin-bottom: var(--outline-thin-width);
  }
}
