@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;900&display=swap");

:root {
  --deep-blue: #022238;
  --blue: #086788;
  --white: #ffffff;
  --big-radius: 50px;
  --light-green: #e1ffe8;
  --grey-text: #6d6d6d;
  --green: #8cde9e;
  --color-green: #4bcc68;
  --color-blue: #086788;
  --outline-width: 20px;
  --outline-md-width: 17px;
  --outline-thin-width: 10px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --active-color: #4bcc68;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blue-gradient {
  background: linear-gradient(
    182.29deg,
    #011627 30.27%,
    #015078 79.84%,
    #096384 94.76%,
    #086788 94.77%
  );
}

.selected-bg {
  background-color: var(--active-color);
}
.faq-bg-gradient {
  background: linear-gradient(
    91.71deg,
    #042b41 1.24%,
    #0e6d88 65.61%,
    #2f6983 98.55%
  );
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
}
