.hero-text {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 4rem;
}
.landing-page-paragraph {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  font-family: "Open Sans";
}
.background-line {
  background: url("../../assets/underline.svg") no-repeat bottom left;
  background-size: 100%;
}
.options-text {
  font-size: 1.1rem;
  font-family: "Montserrat";
  font-weight: 600;
}

.hero-text,
.landing-page-paragraph,
.options-text {
  color: #e1f5f2;
}

.comment {
  font-family: "Montserrat";
  width: 175px;
  height: 62px;
  background-color: #e1f5f2;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: #4d5367;
  line-height: 17.07px;
}

.top-bubble {
  position: absolute;
}

.bottom-bubble {
  position: absolute;
  margin-top: -175px;
  width: 190px;
  height: 75px;
}

.green-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #a4e1b1;
  position: absolute;
  margin-left: -15px !important;
  margin-top: -30px;
}

.section1-wave {
  overflow-x: hidden;
}

@media screen and (max-width: 1200px) {
  .hero-text {
    font-size: 2rem;
  }
  .landing-page-paragraph,
  .options-text {
    font-size: 0.9rem;
    line-height: 19px;
  }
}

@media screen and (max-width: 991px) {
  .comment {
    background: rgba(239, 255, 253, 0.5);
    backdrop-filter: blur(20px);
  }
  .top-bubble {
    margin-top: 30px;
    margin-left: 30px;
  }
  .bottom-bubble {
    margin-top: -300px;
  }
}

@media screen and (min-width: 992px) {
  .top-bubble {
    right: 75px;
  }
  .bottom-bubble {
    margin-left: -80px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .options-text {
    font-size: 0.7rem;
    line-height: 19px;
  }
}

@media screen and (min-width: 1600px) {
  .top-bubble {
    right: 150px;
  }
}

@media screen and (min-width: 1800px) {
  .top-bubble {
    right: 230px;
  }
}

@media screen and (max-width: 375px) {
  .hero-text {
    font-size: 1.5rem;
  }
}
