.get-started-button {
  border-radius: 40px !important;
  background-color: #ffb800 !important;
  color: #021728 !important;
  width: 100%;
  font-size: 20px !important;
  border-color: #ffb800 !important;
}

.get-started-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.fees {
  font-size: 1.25rem;
  font-weight: 400;
}

@media screen and (max-width: 991px) {
  .button-row {
    display: flex;
    justify-content: center;
  }
  .fees {
    font-size: 1rem;
  }
}