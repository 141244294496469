.learners-heading {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #1c8976;
}

.right-border {
  border-right: 1px solid #9b9b9b;
}

@media screen and (max-width: 992px) {
  .fixed-width {
    height: 32px !important;
  }

  .learners-heading {
    font-size: 14px;
    line-height: 17px;
  }
  .right-border {
    border-right: none;
  }
}
