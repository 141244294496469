.contact-form .modal-content {
  background-color: #e1f5f2 !important;
}

.contact-form .contact-form-title {
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #022b4d;
}

.contact-form .contact-form-redirect-msg {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 300;
  color: #022b4d;
}

.contact-form .modal-footer {
  border-top: none !important;
}

.contact-form .contact-form-submit-button {
  background: #014c66 !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  border-color: #014c66 !important;
}

.contact-form .form-label {
  color: #858383 !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.contact-form .form-label-alert {
  color: red !important;
  font-family: "Montserrat" !important;
  font-weight: 300 !important;
  font-size: 14px !important;
}

.contact-form .form-label-info {
  color: #858383 !important;
  font-family: "Montserrat" !important;
  font-weight: 350 !important;
  font-size: 13px !important;
}

.contact-form .form-control,
.contact-form .input-group-text {
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
  color: #267567;
  font-weight: 600;
  font-family: "Montserrat" !important;
}

.contact-form .form-control {
  border-radius: 8px;
}
.contact-form .input-group-text {
  border-radius: 8px 1px 1px 8px;
}

@media screen and (max-width: 992px) {
  .contact-form .contact-form-title {
    font-size: 20px;
  }
}
