.emi-text {
  font-family: "Montserrat";
  font-size: 3rem;
  line-height: 59px;
  font-weight: bold;
  text-align: left;
}

.yellow-color-text {
  font-family: "Montserrat";
  color: #ffb520;
}

.bg-image {
  background: url("../../assets/emi_background.svg"),
    linear-gradient(91.71deg, #042b41 1.24%, #0e6d88 65.61%, #2f6983 98.55%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 992px) {
  .emi-text {
    font-size: 14px;
    line-height: 17px;
  }
  .center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
