.learners-green-bg {
  background-image: url("../../assets/backgrounds/learnersBg1.png"),
    url("../../assets/backgrounds/learnersBg2.png");
  background-size: 100% auto, 100% auto;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
  min-height: 110vh;
}

.learners-bg-image {
  background-image: url("../../assets/backgrounds/ourLearnersBlueBg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.wrapper {
  padding-left: 50px;
  padding-right: 50px;
}

.prev-icon,
.next-icon {
  position: absolute;
  top: 55%;

  display: block;
}

.next-icon {
  right: -10px;
}

.prev-icon {
  left: -10px;
}
@media screen and (min-width: 1600px) {
  .wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .learners-bg-image {
    min-height: 72vh;
  }
  .learners-green-bg {
    min-height: 87vh;
  }
}

@media screen and (max-width: 568px) {
  .span-flex {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .span-flex-margin {
    margin-left: 20px;
  }
}
