.nav-link {
  text-decoration: none;
}

.navigation-menu {
  font-size: 18px;
  line-height: 24.51px;
  font-family: "Open Sans";
}

.navbar-light .navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

@media screen and (max-width: 992px) {
  .navbar-collapse-mobile {
    padding-top: 15%;
    padding-bottom: 15%;
    background: linear-gradient(
      180deg,
      #086788 0%,
      rgba(28, 137, 118, 0.81) 100%
    );
  }
  .navigation-menu.nav-link,
  .sign-up-button {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    color: #ffffff !important;
  }
  .navbar {
    padding-bottom: 0 !important;
  }
}
