.email-text {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Montserrat";
  line-height: 22px;
}

.bg-image {
  background: url("../../assets/footer_bg_image.svg"),
    linear-gradient(91.71deg, #042b41 1.24%, #0e6d88 65.61%, #2f6983 98.55%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 991px) {
  .email-text {
    font-size: 0.6rem;
    line-height: 10px;
  }
  .footer-section {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 350px) {
  .email-text {
    font-size: 0.5rem;
    line-height: 10px;
  }
}
