.main-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  padding: 32px;
  border: 8px solid white;
  color: white;
}

.mask-visible .block-container {
  background-color: #fff;
  border-radius: 20px;
}

.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 1rem;
}

#goal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  position: relative;
  gap: 15px;
}

#goal-form #content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

#goal-form h2 {
  font-size: 2.5rem;
  color: var(--color-green);
}

#goal-form p {
  color: var(--color-blue);
}

#goal-form img {
  width: 7rem;
}

.cursor-pointer {
  cursor: pointer;
}

button {
  border: none;
}

@media screen and (min-width: 680px) {
  #goal-form {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5rem;
  }
}
