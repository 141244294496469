.journey-bg-image {
  background: url("../../assets/journey_background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.journey-text{
  padding-top: 85px;
}
.journey-text,
.journey-text-author {
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  color: #086788;
}
.journey-text-author {
  display: flex;
  justify-content: center;
  font-size: 11px;
}

@media screen and (min-width: 1200px) {
  .journey-bg-image {
    min-height: 90vh;
  }
  .journey-bg-image-row {
    min-height: 90vh !important;
  }
  .journey-text {
    font-size: 3rem;
  }
  .journey-text-author {
    font-size: 24px;
    line-height: 29.26px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .journey-bg-image {
    min-height: 450px;
  }
  .journey-text {
    font-size: 2rem;
  }
  .journey-text-author {
    font-size: 10px;
    line-height: 12.19px;
  }
}
